export const SHARE = 'faShareAlt';
export const SCAN = 'faBarcodeRead';
export const SEARCH = 'faSearch';
export const APPLE = 'faApple';
export const ANDROID = 'faAndroid';
export const HOME_NAV = 'faHome';
export const LOYALTY_CARD_NAV = 'faGiftCard';
export const ADD_NAV = 'faLayerPlus';
export const UPDATE_NAV = 'faWrench';
export const UPDATE_MEDIA_NAV = 'faImage';
export const SUBSCRIPTION_NAV = 'faCreditCard';
export const SUPPORT_NAV = 'faUserHeadset';
export const EMAIL_NAV = 'faPaperPlane';
export const ABOUT_US_NAV = 'faBookSpells';
export const FAQ_NAV = 'faQuestionCircle';
export const SUPPORT_DOC_NAV = 'faFileAlt';
export const TERMS_NAV = 'faBalanceScale';
export const PRIVACY_NAV = 'faUserSecret';
export const COOKIE_NAV = 'faCookieBite';
export const ACCOUNT_NAV = 'faUserCircle';
export const STARS_NAV = 'faStars';
export const START_UP_NAV = 'faLightbulbDollar';
export const CARD_NAME = 'faFileSignature';
export const CARD_DESCRIPTION = 'faFileAlt';
export const CARD_COMPANY = 'faBuilding';
export const CARD_TALLY = 'faTally';
export const CARD_REWARD = 'faStar';
export const CARD_PHONE = 'faPhoneRotary';
export const CARD_WEB = 'faGlobe';
export const CARD_IMAGE_UPLOAD = 'faCloudUpload';
export const CARD_TRASH_UNDO = 'faTrashUndo';
export const EMAIL_USERNAME = 'faUserPlus';
export const EMAIL_ADDRESS = 'faAt';
export const EMAIL_MESSAGE = 'faCommentAltLines';
export const MANAGE_CARD_NAV = 'faIdCard';
export const ACTIVATE_NAV = 'faPlay';
export const DELETE_NAV = 'faTrashAlt';
export const LOYALTY_CARD_SERIAL = 'faAddressCard';
export const LOYALTY_STAMP = 'faStamp';
export const LOYALTY_REDEEM = 'faGiftCard';
export const REWARD = 'faTreasureChest';
export const INFO_ICON = 'faInfoCircle';
export const WARNING_ICON = 'faExclamationTriangle';
export const CRITICAL_ICON = 'faExclamationSquare';
export const REGISTER_ICON = 'faSmilePlus';
export const LOGIN_ICON = 'faSignIn';
export const RETRY = 'faUndoAlt';
export const BUG = 'faBug';
export const VALUES_NAV = 'faSeedling';
