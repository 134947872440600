import localforage from 'localforage';
import { ONCIONUSER, SUBSCRIPTION } from 'state/store';

import { DateTime } from 'luxon';

export const hasSubscription = async () => {
  const oncionuser = await localforage.getItem(ONCIONUSER);
  if (oncionuser === null) {
    return null;
  }
  const { userId = '-1' } = oncionuser;
  if (userId === '-1') {
    return null;
  }
  const subscription = await localforage.getItem(SUBSCRIPTION);
  if (subscription !== null) {
    const { stripeSubscriptionState = '', stripePeriodEnd = 0 } = subscription;
    if (stripeSubscriptionState === 'active' || stripeSubscriptionState === 'beta') {
      return true;
    }

    const endSubTime = DateTime.fromHTTP(stripePeriodEnd);
    const timeNow = DateTime.utc();
    const difference = endSubTime.diff(timeNow);
    const { milliseconds } = difference.toObject();

    const THIRTY_MINUTES = 1800000;
    if (milliseconds > THIRTY_MINUTES) {
      return true;
    }
  }
  return false;
};

export const isBetaUser = async () => {
  const oncionuser = await localforage.getItem(ONCIONUSER);
  if (oncionuser === null) {
    return null;
  }
  const { userId = '-1' } = oncionuser;
  if (userId === '-1') {
    return null;
  }
  const subscription = await localforage.getItem(SUBSCRIPTION);
  if (subscription !== null) {
    const { stripeSubscriptionState = '' } = subscription;
    if (stripeSubscriptionState === 'beta') {
      return true;
    }
  }
  return false;
};
